import "./App.css";

function App() {
  return (
    <div className="bg-light-blue flex flex-col justify-center items-center h-screen">
      <div className="flex flex-col bg-white drop-shadow-md p-7 rounded-lg">
        <div className="flex justify-start p-5">
          <p className="text-3xl text-gray-700 font-bold">"Go to</p>
        </div>
        <div className="flex justify-center p-5">
          <p className="text-3xl text-gray-700">Coopers Rock</p>
        </div>
        <div className="flex justify-end p-5">
          <p className="text-3xl text-gray-700 font-bold">for sunset"</p>
        </div>
      </div>
    </div>
  );
}

export default App;
